import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';
import { Button, Row } from 'react-bootstrap';

import './index.scss';
import './play-on-pc.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { CategoryCard } from '../../modules/cs/main/components/category-card';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const TOGIndexPage: React.FC = () => {
  return (
    <DashboardLayout className={'home-page tog'} game="tog">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Tower of God: New World wiki and tier list </h1>
        <h2>
          Prydwen.gg is a wiki for Tower of God: New World. Check our guides,
          tier lists and reviews for characters available in the game.
        </h2>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Play on PC" />
      <div className="banner tog">
        <div className="cta">
          <h4>Play Tower of God: New World on PC</h4>
          <OutboundLink
            href="https://www.ldplayer.net/games/tower-of-god-new-world-on-pc.html?n=82774712#utm_source=aff&utm_medium=aff&utm_campaign=aff82774712"
            target="_blank"
          >
            <Button variant="primary">Play now on LDPlayer</Button>
          </OutboundLink>
        </div>
      </div>
      <SectionHeader title="Shortcuts" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/tog/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/tog/categories/category_intro.png"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Reroll guide"
          link="/tog/guides/reroll"
          image={
            <StaticImage
              src="../../images/tog/categories/category_reroll.png"
              alt="Reroll guide"
            />
          }
        />
        <CategoryCard
          title="Beginner guide"
          link="/tog/guides/beginner-guide"
          image={
            <StaticImage
              src="../../images/tog/categories/category_beginner.png"
              alt="Beginner guide"
            />
          }
        />
        <CategoryCard
          title="Wishlist tips"
          link="/tog/guides/wishlist-tips"
          image={
            <StaticImage
              src="../../images/tog/categories/category_wishlist.png"
              alt="Wishlist tips"
            />
          }
        />
        <CategoryCard
          title="Meta teams (Story)"
          link="/tog/guides/meta-teams"
          image={
            <StaticImage
              src="../../images/tog/categories/category_meta.jpg"
              alt="Meta teams"
            />
          }
        />
        <CategoryCard
          title="Best Ignition Weapons"
          link="/tog/ignition-weapons"
          image={
            <StaticImage
              src="../../images/tog/categories/category_ign.png"
              alt="Best Ignition Weapons"
            />
          }
        />
        <CategoryCard
          title="Characters"
          link="/tog/characters"
          image={
            <StaticImage
              src="../../images/tog/categories/category_characters.png"
              alt="Reroll guide"
            />
          }
        />
        <CategoryCard
          title="Tier list"
          link="/tog/tier-list"
          image={
            <StaticImage
              src="../../images/tog/categories/category_tier.png"
              alt="Reroll guide"
            />
          }
        />
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default TOGIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Tower of God: New World Wiki & Database | Prydwen Institute"
    description="Prydwen.gg is a wiki for Tower of God: New World. Check our guides, tier lists and reviews for characters available in the game."
    game="tog"
  />
);
